<template>
  <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index">
    <span
      slot="id"
      slot-scope="text"
    >{{text}}</span>
    <span slot="name" slot-scope="record"> {{ record.name || record.value }}</span>
    <span slot="action" slot-scope="record">
            <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="$router.push({ path: `/content/banners/${record.id}`})">
              <i class="fe fe-edit mr-2" />
              Изменить
            </a>
              <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="$router.push({ path: `/content/banners/${record.id}`, query: { is_preview: 'true' }})">
                <small>
                  <i class="fe fe-more-horizontal mr-2" />
                </small>
                Просмотр
              </a>
          </span>
  </a-table>
</template>

<script>

export default {
  name: 'BannersTable',
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название страницы',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Количество баннеров',
          dataIndex: 'count_banners',
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  methods: {
    getCurrentType(id) {
      const type = this.types.find(item => item.id === id)
      return type ? type.name : ''
    },
  },
}
</script>

<style scoped lang="scss">
</style>
